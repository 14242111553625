
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

@import url('_typography.scss');

html,
body {
    font-family: $primary-font;
    height: 100%;
    min-height: 100vh;
    font-size: $font-size;
    background-color: $box-bg-color;
}

.textEllipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-form-item {
    margin-bottom: 16px;
}

.ant-menu-inline {
    .ant-menu-item-selected {
        border-right: 3px solid !important;
    }
}

.ant-menu-title-content {
    overflow: visible !important;
    display: flex !important;
    line-height: 64px !important;
}

.ant-menu.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
    > .ant-menu-item {
        height: 40px;
    }
}

.ant-form-item-label > label.ant-form-item-required::before {
    content: none !important;
}

.ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-inline-start: 4px;
    color: $danger-color;
    font-size: $font-size;
    font-family: $primary-font;
    line-height: 1;
    content: '*';
}

.ant-form-item-label {
    label {
        font-size: $font-size !important;
    }
}

.ant-form-item.checkboxItem {
    height: 26px;
    margin-bottom: 5px !important;
    padding: 0 !important;
    line-height: 1.5 !important;
}

.ant-table-wrapper {
    width: 100% !important;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        font-size: $font-size;
    }
}

.ant-tabs-nav-list .ant-tabs-tab {
    height: 64px;
    line-height: 64px;
}

.ant-tabs-nav {
    margin-bottom: 0 !important;
    background-color: white;
}

.ant-checkbox-input,
.ant-checkbox-inner {
    width: $checkbox-size !important;
    height: $checkbox-size !important;
}

.ant-checkbox-input {
    border-radius: $checkbox-radius !important;
}

.ant-checkbox-wrapper {
    line-height: 1.5 !important;
    font-size: $font-size !important;
}

.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-prev {
    border: 1px solid #d9d9d9 !important;
    background-color: white !important;

    &.ant-pagination-item-active {
        border: 1px solid $primary !important;
    }
}

#components-layout-demo-custom-trigger .trigger {
    font-size: $logo-font-size;
    line-height: $header-height;
    padding: 0 24px;
    cursor: pointer;

    &:hover {
        color: $primary;
    }
}
.ant-form-item-control-input {
    min-height: $input-height !important;
    input {
        height: $input-height;
    }
}

.ant-input-number {
    width: 100%;
}

.ant-select-single {
    .ant-select-selection-item {
        line-height: $input-height !important;
    }
}

.linksTable {
    .ant-table-row {
        cursor: pointer !important;
    }
}

.paymentQRModal {
    .ant-modal-content {
        border-radius: 24px !important;
    }
}

.paymentForm {
    .ant-form-item {
        margin-bottom: 0 !important;
    }

    .ant-form-item-explain-error {
        margin-top: 4px;
        margin-left: 16px;
    }

    .receiptUpload {
        .ant-form-item-explain-error {
            display: none;
        }
    }
}

.commissionTable {
    .ant-form-item-control-input {
        min-height: 0 !important;
    }
    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-table-cell {
        height: 39px;
        padding: 8px;
    }

    .ant-input,
    .ant-input-number,
    .ant-select-selector {
        height: 22px !important;
        border: none !important;
        box-shadow: none !important;

        &:focus {
            outline: none !important;
        }
    }

    .ant-select-selector {
        &:focus,
        &:hover {
            border-color: transparent !important;
            box-shadow: none !important;
        }

        .ant-select-selection-item,
        .ant-select-selection-placeholder,
        .ant-select-selection-search-input {
            line-height: 22px !important;
        }
    }
}
