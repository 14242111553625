// Colors
$primary: #8958e4;
$dark: #001529;
$background-color: #001529;
$hover-color: #252a3e;
$avatar-bg-color: white;
$username-color: white;
$table-header-color: #f0f0f0;
$danger-color: #ff4d4f;
$green-color: #52c41a;
$video-bg-color: #e4d6ff;
$box-bg-color: #f5f5f5;
$dnd-icon-color: #722ed1;
$copy-icon-color: #bfbfbf;
$icon-color: #597ef7;

// Font Settings
$primary-font: 'Roboto', sans-serif;
$font-size: 14px;
$logo-font: 'Righteous', cursive;
$logo-font-weight: 500;
$logo-font-size: 18px;
$username-font-weight: 400;
$username-line-height: 22px;
$font-size-small: 12px;
$font-size-large: 24px;

// Logo Settings
$logo-width: 85px;
$logo-height: 32px;

// Sidebar Settings
$sidebar-width: 256px;
$collapsed-sidebar-width: 80px;
$sidebar-footer-height: 48px;

// Header Settings
$header-height: 48px;
$page-header-height: 78px;
$header-tabs-height: 64px;

// Container Settings
$container-padding: 24px;

// Form Settings
$form-block-padding: 32px 0 36px 73px;
$block-title-margin-bottom: 14px;
$input-width: 70%;
$input-height: 32px;
$form-component-margin-bottom: 24px;

// Checkbox Settings
$checkbox-size: 16px;
$checkbox-radius: 2px;

// Avatar Settings
$avatar-size: 24px;
$avatar-border-radius: 50%;

// General Settings
$menu-container-margin-top: 6px;
$radius: 2px;

//Breakpoints
$mobile-max-size: 768px;
$desktop-medium-size: 1440px;

// User
$submit-button-height: 32px;