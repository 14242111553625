
    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_media.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_mixins.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_variables.scss";

    @import "/builds/datsteam/greenback/frontend/external-admin-panel/src/assets/styles/_typography.scss";

.trigger {
    position: absolute;
    left: 31px;
    font-size: 18px;
}

.sidebarContent {
    height: calc(100vh - #{$header-height} - #{$sidebar-footer-height});
    overflow: auto;
}

.sidebarFooter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $sidebar-footer-height;
    background-color: inherit;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    padding-left: 24px;
}

.sidebarFooterCollapsed {
    @extend .sidebarFooter;
    justify-content: center;
    padding-left: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: $header-height;
    background-color: $dark;
    &__rightPanel {
        display: flex;
        align-items: center;
    }
}

.modeSwitcher {
    margin-right: 24px;
}

.divider {
    margin: 0;
}

.menuContainer {
    margin-top: $menu-container-margin-top;
    border-inline-end: none;
}

.menu {
    border: none;
    background: white;
}

.logo {
    width: $logo-width;
    height: $logo-height;
    font-family: $logo-font;
    font-weight: $logo-font-weight;
    font-size: $logo-font-size;
    display: flex;
    align-items: center;
    color: white;
    .logoUm {
        color: $primary;
    }
}

.subMenu {
    font-size: 14px;
    font-weight: 500;

    div {
        padding-left: 24px;
        width: 100% !important;
        margin: 0 !important;
        height: 64px !important;
    }

    .item {
        margin: 0;
        width: 100%;
        border-radius: 0;
        font-size: 14px;
        padding-left: 36px !important;
        font-weight: 500 !important;

        span {
            font-weight: 400;
        }
    }
}

.menuItemButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}