@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Righteous';
    src: url('../fonts/Righteous/Righteous-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-VariableFont_wght.ttf')
        format('truetype');
    font-style: normal;
    font-display: swap;
}

h1,
h2 {
    font-weight: 600;
}

.body-1 {
    font-size: 16px;
    line-height: 1.5;
}
.body-2 {
    font-weight: bold;
    @extend .body-1;
}

.body-3 {
    font-size: 14px;
    line-height: 1.4;
}

@include media('<=md') {
    .body-1 {
        font-size: 14px;
        line-height: 1.4;
    }
}

@include media('<=sm') {
    h1.ant-typography {
        font-size: 24px;
    }

    h2.ant-typography {
        font-size: 20px;
    }

    h3.ant-typography {
        font-size: 16px;
    }
}
